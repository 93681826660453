import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCCzA4adJbeuLq76sJdzOHj9y8BXSGXZWY",
    authDomain: "avacasa-de3f6.firebaseapp.com",
    databaseURL: "https://avacasa-de3f6-default-rtdb.firebaseio.com",
    projectId: "avacasa-de3f6",
    storageBucket: "avacasa-de3f6.appspot.com",
    messagingSenderId: "54909454821",
    appId: "1:54909454821:web:ab14d59baba5999c52070c",
    measurementId: "G-TMT81FQRGV"
  };
  
  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);