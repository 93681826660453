// services/productAnalysis.js

import axios from 'axios';

const API_KEY = process.env.REACT_APP_AZURE_OPENAI_API_KEY;
const AZURE_ENDPOINT = process.env.REACT_APP_AZURE_OPENAI_ENDPOINT;

export const analyzeProduct = async (imageUrl) => {
  if (!API_KEY || !AZURE_ENDPOINT) {
    throw new Error('Azure OpenAI API key or endpoint is not set in environment variables');
  }

  try {
    const response = await axios.post(
      AZURE_ENDPOINT,
      {
        messages: [
          {
            role: "system",
            content: "You are a fashion, makeup, and skincare product expert. Analyze the given image and provide detailed information about the product. Be specific and accurate in identifying the product type, name, and category. Fill in as many fields as possible based on what you can observe or infer from the image. If you absolutely cannot determine a value for a field, use null."
          },
          {
            role: "user",
            content: [
              { 
                type: "text", 
                text: `Analyze this product and provide a JSON object with the following fields:
                  type (must be one of: clothing, makeup, or skincare),
                  name (be specific, e.g., "Red Matte Lipstick" instead of just "Lipstick"),
                  description,
                  brand,
                  category (be specific, e.g., "Lipstick" for makeup, "T-shirt" for clothing),
                  subcategory (e.g., "Matte Lipstick" for makeup, "Crew Neck T-shirt" for clothing),
                  priceApprox (estimate if not visible, prefix with "Approx."),
                  
                  For clothing, also include:
                  color,
                  material,
                  style,
                  occasion,
                  season (array),
                  careInstructions,
                  size,
                  fit,
                  condition,
                  
                  For makeup, also include:
                  shade,
                  finish,
                  coverage,
                  
                  For skincare, also include:
                  skinType (array),
                  concerns (array),
                  keyIngredients (array, if visible or known),
                  size,
                  
                  Provide as much detail as possible in the description field.
                  Do not include any expiration date information.
                  For ingredients, only include if clearly visible or known, and add a note: '(User should confirm ingredients)'.` 
              },
              { 
                type: "image_url", 
                image_url: { url: imageUrl }
              },
            ],
          },
        ],
        max_tokens: 1000,
      },
      {
        headers: {
          'api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }
    );

    const content = response.data.choices[0].message.content;
    const jsonMatch = content.match(/```json\n([\s\S]*?)\n```/) || content.match(/\{[\s\S]*\}/);
    const jsonString = jsonMatch ? jsonMatch[1] || jsonMatch[0] : content;

    let productInfo = JSON.parse(jsonString);

    // Post-processing and validation
    productInfo = validateAndCleanProductInfo(productInfo);

    return productInfo;
  } catch (error) {
    console.error('Error analyzing product:', error.response ? error.response.data : error.message);
    throw error;
  }
};

function validateAndCleanProductInfo(productInfo) {
  // Ensure the type is one of the allowed values
  if (!['clothing', 'makeup', 'skincare'].includes(productInfo.type)) {
    productInfo.type = 'clothing'; // Default to clothing if type is not recognized
  }

  // Ensure name is not null
  if (!productInfo.name) {
    productInfo.name = productInfo.category || productInfo.type || 'Unknown Product';
  }

  // Ensure category is not null
  if (!productInfo.category) {
    if (productInfo.type === 'makeup') {
      productInfo.category = 'Other Makeup';
    } else if (productInfo.type === 'skincare') {
      productInfo.category = 'Other Skincare';
    } else {
      productInfo.category = 'Other Clothing';
    }
  }

  // Add empty arrays for fields that should be arrays if they're null
  const arrayFields = ['season', 'skinType', 'concerns', 'keyIngredients'];
  arrayFields.forEach(field => {
    if (!productInfo[field]) {
      productInfo[field] = [];
    }
  });

  // Ensure subcategory is present
  if (!productInfo.subcategory) {
    productInfo.subcategory = productInfo.category;
  }

  // Ensure price is prefixed with "Approx."
  if (productInfo.priceApprox && !productInfo.priceApprox.startsWith('Approx.')) {
    productInfo.priceApprox = `Approx. ${productInfo.priceApprox}`;
  }

  // Add note to ingredients if present
  if (productInfo.keyIngredients && productInfo.keyIngredients.length > 0) {
    productInfo.keyIngredients.push('(User should confirm ingredients)');
  }

  // Remove any unexpected fields
  const allowedFields = [
    'type', 'name', 'description', 'brand', 'category', 'subcategory', 'priceApprox',
    'color', 'material', 'style', 'occasion', 'season', 'careInstructions', 'size', 'fit', 'condition',
    'shade', 'finish', 'coverage',
    'skinType', 'concerns', 'keyIngredients'
  ];
  Object.keys(productInfo).forEach(key => {
    if (!allowedFields.includes(key)) {
      delete productInfo[key];
    }
  });

  return productInfo;
}