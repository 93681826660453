// azureSpeechService.js
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const speechConfig = sdk.SpeechConfig.fromSubscription(
  process.env.REACT_APP_AZURE_SPEECH_KEY,
  process.env.REACT_APP_AZURE_SPEECH_REGION
);
speechConfig.speechRecognitionLanguage = 'en-US';


export const startSpeechRecognition = (onRecognized, onError) => {
  const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
  let isDisposed = false;

  recognizer.recognizeOnceAsync(
    (result) => {
      if (result.reason === sdk.ResultReason.RecognizedSpeech) {
        onRecognized(result.text);
      } else {
        onError('Speech was cancelled or could not be recognized.');
      }
      if (!isDisposed) {
        recognizer.close();
        isDisposed = true;
      }
    },
    (err) => {
      onError(`ERROR: ${err}`);
      if (!isDisposed) {
        recognizer.close();
        isDisposed = true;
      }
    }
  );

  return () => {
    if (!isDisposed) {
      recognizer.close();
      isDisposed = true;
    }
  };
};

export const synthesizeSpeech = async (text) => {
  return new Promise((resolve, reject) => {
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          resolve(result.audioData);
        } else {
          reject(new Error('Speech synthesis canceled'));
        }
        synthesizer.close();
      },
      (error) => {
        reject(error);
        synthesizer.close();
      }
    );
  });
};