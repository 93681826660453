import React, { useState, useRef, useEffect } from 'react';
import { Mic, X, Volume2, VolumeX, Camera, RefreshCw, CameraOff } from 'lucide-react';
import axios from 'axios';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { handleVoiceInteraction } from '../services/voiceopenai';

const VoiceModePopup = ({ 
  isOpen, 
  onClose, 
  darkMode, 
  currentUser, 
  memories, 
  messages, 
  onMessageReceived,
  audioRef
}) => {
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [avatarSynthesizer, setAvatarSynthesizer] = useState(null);
  const [peerConnection, setPeerConnection] = useState(null);
  const [isSessionStarted, setIsSessionStarted] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cameraFacing, setCameraFacing] = useState('user');
  const avatarVideoRef = useRef(null);
  const avatarAudioRef = useRef(null);
  const userVideoRef = useRef(null);
  const microphoneStreamRef = useRef(null);
  const cameraStreamRef = useRef(null);

  const cogSvcRegion = process.env.REACT_APP_AZURE_SPEECH_REGION;
  const cogSvcSubKey = process.env.REACT_APP_AZURE_SPEECH_KEY;

  useEffect(() => {
    if (isOpen) {
      startSession();
    } else {
      stopSession();
    }
    return () => {
      stopSession();
    };
  }, [isOpen]);

  const startSession = async () => {
    setError(null);
    console.log('Starting session...');
    const speechSynthesisConfig = SpeechSDK.SpeechConfig.fromSubscription(cogSvcSubKey, cogSvcRegion);
    speechSynthesisConfig.speechSynthesisVoiceName = "en-US-SaraNeural";
    
    speechSynthesisConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Audio24Khz160KBitRateMonoMp3;
    speechSynthesisConfig.setProperty("SpeechSynthesisAutoStyle", "true");
  
    const saraStyles = [
      "cheerful", "sad", "angry", "excited", "friendly", 
      "terrified", "shouting", "unfriendly", "whispering", 
      "hopeful", "empathetic", "newscast", "narration-professional"
    ];
    speechSynthesisConfig.setProperty("SpeechSynthesisAutoStyleList", saraStyles.join("|"));
  
    const videoFormat = new SpeechSDK.AvatarVideoFormat();
    const avatarConfig = new SpeechSDK.AvatarConfig('meg', 'formal', videoFormat);
    avatarConfig.backgroundImage = 'https://ai.avacasa.ai/re/bgvid3.jpg';
  
    const newAvatarSynthesizer = new SpeechSDK.AvatarSynthesizer(speechSynthesisConfig, avatarConfig);
    console.log('AvatarSynthesizer created:', newAvatarSynthesizer);
    setAvatarSynthesizer(newAvatarSynthesizer);
  
    try {
      console.log('Fetching token...');
      const response = await axios.get(`https://${cogSvcRegion}.tts.speech.microsoft.com/cognitiveservices/avatar/relay/token/v1`, {
        headers: {
          'Ocp-Apim-Subscription-Key': cogSvcSubKey
        }
      });
      console.log('Token fetched successfully');
      const { Urls, Username, Password } = response.data;
      setupWebRTC(newAvatarSynthesizer, Urls[0], Username, Password);
    } catch (error) {
      console.error('Error starting session:', error);
      setError('Failed to start the session. Please try again later.');
    }
  };
    
  const setupWebRTC = (synthesizer, iceServerUrl, iceServerUsername, iceServerCredential) => {
    console.log('Setting up WebRTC...');
    const newPeerConnection = new RTCPeerConnection({
      iceServers: [{
        urls: [iceServerUrl],
        username: iceServerUsername,
        credential: iceServerCredential
      }]
    });

    newPeerConnection.ontrack = (event) => {
      console.log('Received track:', event.track.kind);
      if (event.track.kind === 'video') {
        console.log('Setting video source');
        avatarVideoRef.current.srcObject = event.streams[0];
      } else if (event.track.kind === 'audio') {
        console.log('Setting audio source');
        avatarAudioRef.current.srcObject = event.streams[0];
      }
    };

    newPeerConnection.oniceconnectionstatechange = () => {
      console.log('ICE connection state:', newPeerConnection.iceConnectionState);
      if (newPeerConnection.iceConnectionState === 'connected') {
        console.log('WebRTC connection fully established');
        setIsSessionStarted(true);
      }
    };

    newPeerConnection.addTransceiver('video', { direction: 'sendrecv' });
    newPeerConnection.addTransceiver('audio', { direction: 'sendrecv' });

    console.log('Starting avatar...');
    synthesizer.startAvatarAsync(newPeerConnection).then((result) => {
      if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
        console.log('Avatar started successfully');
        setError(null);
      } else {
        console.error('Failed to start avatar. Reason:', result.reason);
        console.error('Error details:', result.errorDetails);
        if (result.errorDetails.includes('throttled') || result.errorDetails.includes('exceeded')) {
          setError('Our servers are currently busy. Please try again later.');
        } else {
          setError('An error occurred while starting the avatar. Please try again.');
        }
      }
    }).catch((error) => {
      console.error('Error starting avatar:', error);
      if (error.message.includes('throttled') || error.message.includes('exceeded')) {
        setError('Our servers are currently busy. Please try again later.');
      } else {
        setError('An error occurred while starting the avatar. Please try again.');
      }
    });

    setPeerConnection(newPeerConnection);
  };

  const stopSession = () => {
    console.log('Stopping session...');
    if (avatarSynthesizer) {
      avatarSynthesizer.close();
      setAvatarSynthesizer(null);
    }
    if (peerConnection) {
      peerConnection.close();
      setPeerConnection(null);
    }
    stopMicrophone();
    stopCamera();
    setIsSessionStarted(false);
    setError(null);
    setCameraEnabled(false);
    console.log('Session stopped');
  };

  const stopMicrophone = () => {
    if (microphoneStreamRef.current) {
      microphoneStreamRef.current.getTracks().forEach(track => {
        track.stop();
        console.log('Microphone track stopped:', track.id);
      });
      microphoneStreamRef.current = null;
    }
  };

  const stopCamera = () => {
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach(track => {
        track.stop();
        console.log('Camera track stopped:', track.id);
      });
      cameraStreamRef.current = null;
    }
    if (userVideoRef.current) {
      userVideoRef.current.srcObject = null;
    }
  };

  const handleVoiceInteractionStart = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setIsListening(true);
    try {
      if (audioRef.current && audioRef.current.state === 'suspended') {
        await audioRef.current.resume();
      }
  
      const response = await handleVoiceInteraction(
        currentUser, 
        memories, 
        messages.map(msg => ({ role: msg.sender === 'user' ? 'user' : 'assistant', content: msg.text })),
        audioEnabled,
        userVideoRef.current,
        audioRef.current,
        avatarSynthesizer,
        peerConnection
      );
  
      setIsListening(false);
      onMessageReceived(response.text);
      
      setIsSpeaking(true);
      await new Promise(resolve => setTimeout(resolve, response.text.length * 50));
      setIsSpeaking(false);
    } catch (error) {
      console.error('Error in voice interaction:', error);
      setError('An error occurred during the voice interaction. Please try again.');
    } finally {
      setIsListening(false);
      setIsProcessing(false); // Remove the timeout here
    }
  };
  
  const toggleAudio = () => {
    setAudioEnabled(!audioEnabled);
  };

  const toggleCamera = async () => {
    if (cameraEnabled) {
      stopCamera();
      setCameraEnabled(false);
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: cameraFacing } });
        userVideoRef.current.srcObject = stream;
        cameraStreamRef.current = stream;
        setCameraEnabled(true);
      } catch (error) {
        console.error('Error accessing camera:', error);
        setError('Failed to access the camera. Please check your permissions and try again.');
      }
    }
  };

  const switchCamera = async () => {
    const newFacing = cameraFacing === 'user' ? 'environment' : 'user';
    setCameraFacing(newFacing);
    if (cameraEnabled) {
      stopCamera();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: newFacing } });
        userVideoRef.current.srcObject = stream;
        cameraStreamRef.current = stream;
      } catch (error) {
        console.error('Error switching camera:', error);
        setError('Failed to switch the camera. Please try again.');
      }
    }
  };

  const handleTryAgain = () => {
    setError(null);
    startSession();
  };

  const handleClose = () => {
    stopSession();
    onClose();
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-lg p-4 w-full max-w-md mx-4`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-avacasa-500">Speak with Ava</h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors">
            <X size={24} />
          </button>
        </div>
        {error ? (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            <p>{error}</p>
            <button 
              onClick={handleTryAgain}
              className="mt-3 px-4 py-2 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors flex items-center"
            >
              <RefreshCw size={18} className="mr-2" />
              Try Again
            </button>
          </div>
        ) : (
          <>
            <div className="mb-4 relative rounded-lg overflow-hidden shadow-md" style={{paddingTop: '100%'}}>
              <video ref={avatarVideoRef} className="absolute top-0 left-0 w-full h-full object-cover" autoPlay playsInline />
              <audio ref={avatarAudioRef} autoPlay />
              <video 
                ref={userVideoRef} 
                className={`absolute top-0 right-0 w-1/4 h-1/4 object-cover ${cameraEnabled ? '' : 'hidden'}`} 
                autoPlay 
                playsInline 
                muted
              />
            </div>
            <p className="mb-3 text-sm">Tap the microphone to start speaking.</p>
            <div className="flex flex-col items-center mb-4">
              <button
                onClick={handleVoiceInteractionStart}
                disabled={isListening || isSpeaking || !isSessionStarted || isProcessing}
                className={`w-full py-3 px-4 rounded-full text-lg font-semibold ${
                  isListening || isSpeaking || !isSessionStarted || isProcessing
                    ? 'bg-avacasa-300 text-white opacity-50 cursor-not-allowed'
                    : 'bg-avacasa-500 text-white hover:bg-avacasa-600'
                } transition-all duration-300 flex items-center justify-center shadow-md mb-3`}
              >
                <Mic size={24} className="mr-2" />
                {isListening ? 'Listening...' : isSpeaking ? 'Speaking...' : 'Start Speaking'}
              </button>
              <div className="flex justify-center w-full">
                <button
                  onClick={toggleAudio}
                  className={`p-3 rounded-full ${
                    audioEnabled
                      ? 'bg-green-500 text-white'
                      : 'bg-red-500 text-white'
                  } hover:opacity-90 transition-all duration-300 shadow-md mr-3`}
                >
                  {audioEnabled ? <Volume2 size={24} /> : <VolumeX size={24} />}
                </button>
                <button
                  onClick={toggleCamera}
                  className={`p-3 rounded-full ${
                    cameraEnabled
                      ? 'bg-green-500 text-white'
                      : 'bg-red-500 text-white'
                  } hover:opacity-90 transition-all duration-300 shadow-md`}
                >
                  {cameraEnabled ? <Camera size={24} /> : <CameraOff size={24} />}
                </button>
                {isMobile && cameraEnabled && (
                  <button
                    onClick={switchCamera}
                    className="p-3 rounded-full bg-blue-500 text-white hover:opacity-90 transition-all duration-300 shadow-md ml-3"
                  >
                    <RefreshCw size={24} />
                  </button>
                )}
              </div>
            </div>
            <div className="flex justify-between text-xs text-gray-500">
              <p>{audioEnabled ? 'Audio on' : 'Audio off'}</p>
              <p>
                {cameraEnabled 
                  ? (isMobile 
                      ? (cameraFacing === 'user' ? 'Front camera' : 'Back camera')
                      : 'Camera on')
                  : 'Camera off'}
              </p>
            </div>
          </>
        )}
        <p className="text-xs text-yellow-500 mt-4 bg-yellow-100 dark:bg-yellow-900 p-2 rounded-lg">
          Disclaimer: This voice mode feature is experimental and may not work as expected.
        </p>
      </div>
    </div>
  );
};

export default VoiceModePopup;