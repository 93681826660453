import React, { useState } from 'react';
import { X, Upload } from 'lucide-react';

const VersusMode = ({ onClose, darkMode, onImagesSelect }) => {
  const [selectedImages, setSelectedImages] = useState([null, null]);
  const [previewUrls, setPreviewUrls] = useState([null, null]);

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const newSelectedImages = [...selectedImages];
      newSelectedImages[index] = file;
      setSelectedImages(newSelectedImages);

      const reader = new FileReader();
      reader.onloadend = () => {
        const newPreviewUrls = [...previewUrls];
        newPreviewUrls[index] = reader.result;
        setPreviewUrls(newPreviewUrls);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmSelection = () => {
    if (selectedImages[0] && selectedImages[1] && previewUrls[0] && previewUrls[1]) {
      onImagesSelect(selectedImages, previewUrls);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-96 max-w-full`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Versus Mode</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>
        <div className="mb-4 flex space-x-4">
          {[0, 1].map((index) => (
            <div key={index} className="flex-1">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, index)}
                className="hidden"
                id={`versus-mode-upload-${index}`}
              />
              <label
                htmlFor={`versus-mode-upload-${index}`}
                className={`flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer ${
                  darkMode ? 'border-gray-600 hover:border-gray-500' : 'border-gray-300 hover:border-gray-400'
                }`}
              >
                {previewUrls[index] ? (
                  <img src={previewUrls[index]} alt={`Selected ${index + 1}`} className="max-h-full rounded object-contain" />
                ) : (
                  <div className="flex flex-col items-center">
                    <Upload size={24} className={darkMode ? 'text-gray-400' : 'text-gray-600'} />
                    <span className="mt-2">Outfit {index + 1}</span>
                  </div>
                )}
              </label>
            </div>
          ))}
        </div>
        <button
          onClick={handleConfirmSelection}
          disabled={!selectedImages[0] || !selectedImages[1]}
          className={`w-full py-2 px-4 rounded-lg ${
            selectedImages[0] && selectedImages[1]
              ? 'bg-avacasa-500 text-white hover:bg-avacasa-600'
              : `${darkMode ? 'bg-gray-700 text-gray-400' : 'bg-gray-200 text-gray-500'} cursor-not-allowed`
          } transition-colors`}
        >
          Compare Outfits
        </button>
      </div>
    </div>
  );
};

export default VersusMode;