import React, { useState } from 'react';
import { X } from 'lucide-react';

const TravelPacking = ({ onClose, darkMode, onSubmit }) => {
  const [travelInfo, setTravelInfo] = useState({
    destination: '',
    startDate: '',
    endDate: '',
    purpose: '',
    additionalInfo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTravelInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(travelInfo);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-96 max-w-full`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Travel Packing</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Destination</label>
            <input
              type="text"
              name="destination"
              value={travelInfo.destination}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              placeholder="E.g., Paris, France"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={travelInfo.startDate}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">End Date</label>
            <input
              type="date"
              name="endDate"
              value={travelInfo.endDate}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Purpose of Travel</label>
            <select
              name="purpose"
              value={travelInfo.purpose}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select purpose</option>
              <option value="business">Business</option>
              <option value="leisure">Leisure</option>
              <option value="both">Both Business and Leisure</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Additional Information</label>
            <textarea
              name="additionalInfo"
              value={travelInfo.additionalInfo}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              placeholder="Any specific activities or events planned?"
              rows="3"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors"
          >
            Get Packing List
          </button>
        </form>
      </div>
    </div>
  );
};

export default TravelPacking;