import { db } from '../firebase';
import { collection, doc, setDoc, getDoc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';

export const saveMemory = async (userId, memory) => {
  const memoriesRef = doc(db, 'users', userId, 'memories', memory.key);
  await setDoc(memoriesRef, { value: memory.value }, { merge: true });
};

export const getMemories = async (userId) => {
  try {
    const memoriesRef = collection(db, 'users', userId, 'memories');
    const snapshot = await getDocs(memoriesRef);
    
    if (snapshot.empty) {
      return [];
    }
    
    return snapshot.docs.map(doc => ({ key: doc.id, value: doc.data().value }));
  } catch (error) {
    console.error('Error fetching memories:', error);
    throw error;
  }
};
export const updateMemory = async (userId, oldKey, newMemory) => {
  const oldMemoryRef = doc(db, 'users', userId, 'memories', oldKey);
  const newMemoryRef = doc(db, 'users', userId, 'memories', newMemory.key);

  if (oldKey !== newMemory.key) {
    // If the key has changed, delete the old document and create a new one
    await deleteDoc(oldMemoryRef);
    await setDoc(newMemoryRef, { value: newMemory.value });
  } else {
    // If only the value has changed, update the existing document
    await updateDoc(oldMemoryRef, { value: newMemory.value });
  }
};

export const deleteMemory = async (userId, key) => {
  const memoryRef = doc(db, 'users', userId, 'memories', key);
  await deleteDoc(memoryRef);
};

export const deleteAllMemories = async (userId) => {
  const memoriesRef = collection(db, 'users', userId, 'memories');
  const snapshot = await getDocs(memoriesRef);
  const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
  await Promise.all(deletePromises);
};