import React, { useState } from 'react';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';

const StyleQuiz = ({ onClose, darkMode, onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({
    favoriteColors: [],
    stylePreferences: [],
    occasionPreference: '',
    comfortLevel: '',
    fashionIcon: '',
    favoriteBrands: '',
    budgetRange: '',
    seasonalPreference: '',
    accessoryPreference: [],
    fitPreference: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers(prev => ({ ...prev, [name]: value }));
  };

  const handleMultipleChoice = (name, value) => {
    setAnswers(prev => {
      const updatedChoices = prev[name].includes(value)
        ? prev[name].filter(item => item !== value)
        : [...prev[name], value].slice(0, 3);
      return { ...prev, [name]: updatedChoices };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(answers);
  };

  const steps = [
    {
      title: 'Style Preferences',
      fields: (
        <>
          <div className="mb-4">
            <label className="block mb-2">Favorite Colors (Select up to 3)</label>
            <div className="flex flex-wrap gap-2">
              {['Red', 'Blue', 'Green', 'Yellow', 'Purple', 'Pink', 'Black', 'White', 'Gray', 'Brown'].map((color) => (
                <button
                  key={color}
                  type="button"
                  onClick={() => handleMultipleChoice('favoriteColors', color)}
                  className={`px-3 py-1 rounded ${
                    answers.favoriteColors.includes(color)
                      ? 'bg-avacasa-500 text-white'
                      : darkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-200 text-gray-800'
                  }`}
                >
                  {color}
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Style Preferences (Select up to 3)</label>
            <div className="flex flex-wrap gap-2">
              {['Casual', 'Formal', 'Bohemian', 'Preppy', 'Sporty', 'Vintage', 'Minimalist', 'Eclectic'].map((style) => (
                <button
                  key={style}
                  type="button"
                  onClick={() => handleMultipleChoice('stylePreferences', style)}
                  className={`px-3 py-1 rounded ${
                    answers.stylePreferences.includes(style)
                      ? 'bg-avacasa-500 text-white'
                      : darkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-200 text-gray-800'
                  }`}
                >
                  {style}
                </button>
              ))}
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Occasion & Comfort',
      fields: (
        <>
          <div className="mb-4">
            <label className="block mb-2">Occasion Preference</label>
            <select
              name="occasionPreference"
              value={answers.occasionPreference}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select your occasion preference</option>
              {['Everyday', 'Work', 'Night Out', 'Special Events', 'Travel'].map((occasion) => (
                <option key={occasion} value={occasion}>{occasion}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Comfort Level</label>
            <select
              name="comfortLevel"
              value={answers.comfortLevel}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select your comfort level</option>
              {['Very Comfortable', 'Somewhat Comfortable', 'Neutral', 'Somewhat Uncomfortable', 'Very Uncomfortable'].map((level) => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>
        </>
      ),
    },
    {
      title: 'Fashion Inspiration',
      fields: (
        <>
          <div className="mb-4">
            <label className="block mb-2">Fashion Icon</label>
            <input
              type="text"
              name="fashionIcon"
              value={answers.fashionIcon}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              placeholder="E.g., Audrey Hepburn, David Beckham"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Favorite Brands</label>
            <input
              type="text"
              name="favoriteBrands"
              value={answers.favoriteBrands}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              placeholder="Enter your favorite brands, separated by commas"
            />
          </div>
        </>
      ),
    },
    {
      title: 'Additional Preferences',
      fields: (
        <>
          <div className="mb-4">
            <label className="block mb-2">Budget Range</label>
            <select
              name="budgetRange"
              value={answers.budgetRange}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select your budget range</option>
              {['Budget-friendly', 'Mid-range', 'High-end', 'Luxury'].map((range) => (
                <option key={range} value={range}>{range}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Seasonal Preference</label>
            <select
              name="seasonalPreference"
              value={answers.seasonalPreference}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select your seasonal preference</option>
              {['Spring/Summer', 'Fall/Winter', 'Year-round'].map((season) => (
                <option key={season} value={season}>{season}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Accessory Preference (Select up to 3)</label>
            <div className="flex flex-wrap gap-2">
              {['Jewelry', 'Bags', 'Shoes', 'Scarves', 'Hats', 'Belts'].map((accessory) => (
                <button
                  key={accessory}
                  type="button"
                  onClick={() => handleMultipleChoice('accessoryPreference', accessory)}
                  className={`px-3 py-1 rounded ${
                    answers.accessoryPreference.includes(accessory)
                      ? 'bg-avacasa-500 text-white'
                      : darkMode
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-200 text-gray-800'
                  }`}
                >
                  {accessory}
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Fit Preference</label>
            <select
              name="fitPreference"
              value={answers.fitPreference}
              onChange={handleChange}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select your fit preference</option>
              {['Tight', 'Fitted', 'Regular', 'Loose', 'Oversized'].map((fit) => (
                <option key={fit} value={fit}>{fit}</option>
              ))}
            </select>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Style Quiz</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <h3 className="text-xl font-semibold mb-4">{steps[currentStep].title}</h3>
          {steps[currentStep].fields}
          <div className="flex justify-between mt-6">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={() => setCurrentStep(prev => prev - 1)}
                className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors flex items-center"
              >
                <ChevronLeft size={20} className="mr-1" /> Previous
              </button>
            )}
            {currentStep < steps.length - 1 ? (
              <button
                type="button"
                onClick={() => setCurrentStep(prev => prev + 1)}
                className="py-2 px-4 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors flex items-center ml-auto"
              >
                Next <ChevronRight size={20} className="ml-1" />
              </button>
            ) : (
              <button
                type="submit"
                className="py-2 px-4 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors ml-auto"
              >
                Submit Quiz
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default StyleQuiz;