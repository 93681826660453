import React, { useState, useEffect } from 'react';
import { analyzeImage, generateSkincareRoutine } from '../services/skincareAI';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Camera, Loader, Save, ChevronRight, ChevronLeft, Eye, Plus, Edit, Trash } from 'lucide-react';
import { doc, getDoc, setDoc, deleteField } from 'firebase/firestore';
import { db } from '../firebase';

const steps = [
  { id: 'personal', title: 'Personal Information' },
  { id: 'image', title: 'Skin Image Analysis' },
  { id: 'analysis', title: 'Skin Analysis Results' },
  { id: 'concerns', title: 'Skin Concerns' },
  { id: 'goals', title: 'Skincare Goals' },
  { id: 'routine', title: 'Your Skincare Routine' },
];

const SkincareRoutineBuilder = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { currentUser, darkMode } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [image, setImage] = useState(null);
  const [skinAnalysis, setSkinAnalysis] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    age: '',
    gender: '',
    skinType: '',
    climate: '',
    lifestyle: '',
  });
  const [concerns, setConcerns] = useState([]);
  const [goals, setGoals] = useState([]);
  const [preferredBrands, setPreferredBrands] = useState([]);
  const [budgetRange, setBudgetRange] = useState('');
  const [routine, setRoutine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [hasSavedRoutine, setHasSavedRoutine] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setPersonalInfo(prevInfo => ({
        ...prevInfo,
        name: currentUser.name || '',
        age: currentUser.age || '',
        gender: currentUser.gender || '',
        skinType: currentUser.skinType || '',
      }));
      checkForSavedRoutine();
    }
  }, [currentUser]);

  const checkForSavedRoutine = async () => {
    if (!currentUser) return;

    setLoading(true);
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().skincare) {
        setHasSavedRoutine(true);
      }
    } catch (error) {
      console.error('Error checking for saved routine:', error);
    } finally {
      setLoading(false);
    }
  };

  const viewSavedRoutine = async (editMode = false) => {
    if (!currentUser) return;
  
    setLoading(true);
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().skincare) {
        setRoutine(userDoc.data().skincare);
        setIsEditMode(editMode);
        setCurrentStep(steps.length - 1); // Move to the last step (routine display)
      }
    } catch (error) {
      console.error('Error fetching saved routine:', error);
      setError('Failed to fetch saved routine. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setImage(file);
    setLoading(true);
    setError(null);
    try {
      const analysis = await analyzeImage(file);
      setSkinAnalysis(analysis);
      setConcerns(analysis.concerns || []);
      setCurrentStep(2); // Move to analysis step after successful upload
    } catch (error) {
      console.error('Error analyzing image:', error);
      setError('Failed to analyze image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const userData = { 
        personalInfo, 
        skinAnalysis, 
        concerns, 
        goals, 
        preferredBrands, 
        budgetRange 
      };
      const generatedRoutine = await generateSkincareRoutine(userData);
      setRoutine(generatedRoutine);
      setCurrentStep(5); // Move to routine step
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to generate skincare routine. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const saveRoutineToFirestore = async () => {
    if (!currentUser || !routine) return;
  
    setLoading(true);
    setError(null);
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await setDoc(userDocRef, { skincare: routine }, { merge: true });
      setSaveSuccess(true);
      setIsEditMode(false);
      setHasSavedRoutine(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error('Error saving routine:', error);
      setError('Failed to save skincare routine. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const deleteRoutine = async () => {
    if (!currentUser) return;
  
    setLoading(true);
    setError(null);
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await setDoc(userDocRef, { skincare: deleteField() }, { merge: true });
      setRoutine(null);
      setHasSavedRoutine(false);
      setCurrentStep(0);
    } catch (error) {
      console.error('Error deleting routine:', error);
      setError('Failed to delete skincare routine. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const startNewRoutine = () => {
    setRoutine(null);
    setSkinAnalysis(null);
    setImage(null);
    setConcerns([]);
    setGoals([]);
    setPreferredBrands([]);
    setBudgetRange('');
    setCurrentStep(1);
  };
  
  const renderSkinAnalysis = () => {
    if (!skinAnalysis) return null;

    const sections = [
      { title: 'Skin Type and Characteristics', items: ['skinType', 'sensitivity', 'oiliness', 'hydration'] },
      { title: 'Texture and Appearance', items: ['texture', 'firmness', 'elasticity', 'radiance'] },
      { title: 'Specific Concerns', items: ['acne', 'blackheads', 'whiteheads', 'pores', 'redness', 'inflammation'] },
      { title: 'Aging Signs', items: ['fineLines', 'wrinkles', 'ageSpots', 'sunDamage'] },
      { title: 'Pigmentation', items: ['evenness', 'darkSpots', 'melasma', 'hyperpigmentation'] },
      { title: 'Under-eye Area', items: ['darkCircles', 'puffiness', 'crowsFeet'] },
      { title: 'Skin Barrier', items: ['barrierHealth', 'sensitivity', 'reactivity'] },
      { title: 'Overall Health', items: ['overallHealth', 'vitality', 'resilience'] },
    ];

    return (
      <div className="mb-8">
        <h3 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Comprehensive Skin Analysis</h3>
        {sections.map((section, index) => (
          <div key={index} className="mb-4">
            <h4 className={`text-xl font-semibold mb-2 ${darkMode ? 'text-white' : 'text-gray-800'}`}>{section.title}</h4>
            <div className={`bg-gray-100 p-4 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              {section.items.map((item) => (
                <p key={item} className={`mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  <strong>{item.charAt(0).toUpperCase() + item.slice(1)}:</strong> {skinAnalysis[item]}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderInitialOptions = () => (
    <div className="flex flex-col space-y-4">
      <button
        onClick={() => viewSavedRoutine(false)}
        className={`px-6 py-3 ${darkMode ? 'bg-avacasa-600' : 'bg-avacasa-500'} text-white rounded-lg text-lg font-semibold hover:bg-avacasa-600 transition-colors flex items-center justify-center`}
      >
        <Eye size={18} className="mr-2" />
        View Saved Routine
      </button>
      <button
        onClick={() => viewSavedRoutine(true)}
        className={`px-6 py-3 ${darkMode ? 'bg-blue-600' : 'bg-blue-500'} text-white rounded-lg text-lg font-semibold hover:bg-blue-600 transition-colors flex items-center justify-center`}
      >
        <Edit size={18} className="mr-2" />
        Edit Saved Routine
      </button>
      <button
        onClick={startNewRoutine}
        className={`px-6 py-3 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} ${darkMode ? 'text-white' : 'text-gray-800'} rounded-lg text-lg font-semibold hover:bg-opacity-80 transition-colors flex items-center justify-center`}
      >
        <Plus size={18} className="mr-2" />
        Create New Routine
      </button>
    </div>
  );

  const renderStepContent = () => {
    if (currentStep === 0 && hasSavedRoutine) {
      return renderInitialOptions();
    }

    switch (steps[currentStep].id) {
      case 'personal':
        return (
          <div className="space-y-4">
            {Object.entries(personalInfo).map(([key, value]) => (
              <div key={key}>
                <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}:
                </label>
                <input
                  type="text"
                  value={value}
                  onChange={(e) => setPersonalInfo({...personalInfo, [key]: e.target.value})}
                  className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
                />
              </div>
            ))}
          </div>
        );
      case 'image':
        return (
          <div className="space-y-4">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
              id="skin-image-upload"
            />
            <label
              htmlFor="skin-image-upload"
              className={`inline-flex items-center px-4 py-2 ${darkMode ? 'bg-avacasa-600' : 'bg-avacasa-500'} text-white rounded-md cursor-pointer hover:bg-avacasa-600 transition-colors`}
            >
              <Camera size={18} className="mr-2" />
              Upload Face Image
            </label>
            {loading && (
              <div className="flex items-center mt-2">
                <Loader className="animate-spin mr-2" size={18} />
                <span>Analyzing image...</span>
              </div>
            )}
            {image && (
              <div className="mt-4 max-w-md mx-auto">
                <img src={URL.createObjectURL(image)} alt="Uploaded skin" className="w-full h-auto rounded-lg" />
              </div>
            )}
          </div>
        );
      case 'analysis':
        return renderSkinAnalysis();
      case 'concerns':
        return (
          <div className="space-y-4">
            {skinAnalysis?.concerns.map((concern) => (
              <label key={concern} className={`flex items-center ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                <input
                  type="checkbox"
                  value={concern}
                  checked={concerns.includes(concern)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setConcerns([...concerns, concern]);
                    } else {
                      setConcerns(concerns.filter(c => c !== concern));
                    }
                  }}
                  className="mr-2 form-checkbox h-5 w-5 text-avacasa-500"
                />
                {concern}
              </label>
            ))}
          </div>
        );
      case 'goals':
        return (
          <div className="space-y-6">
            <div>
              <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Skincare Goals:
              </label>
              <input
                type="text"
                value={goals.join(', ')}
                onChange={(e) => setGoals(e.target.value.split(', '))}
                className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
                placeholder="e.g., hydration, anti-aging, acne control"
              />
            </div>
            <div>
              <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Preferred Skincare Brands:
              </label>
              <input
                type="text"
                value={preferredBrands.join(', ')}
                onChange={(e) => setPreferredBrands(e.target.value.split(', '))}
                className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
                placeholder="e.g., Dolce&Gabbana, CeraVe, La Roche-Posay"
              />
            </div>
            <div>
              <label className={`block mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Budget Range:
              </label>
              <select
                value={budgetRange}
                onChange={(e) => setBudgetRange(e.target.value)}
                className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'}`}
              >
                <option value="">Select a budget range</option>
                <option value="budget">Budget-friendly</option>
                <option value="mid-range">Mid-range</option>
                <option value="high-end">High-end</option>
                <option value="luxury">Luxury</option>
              </select>
            </div>
          </div>
        );
        
        case 'routine':
          return routine ? (
            <div className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} p-6 rounded-lg`}>
              {['morningRoutine', 'eveningRoutine'].map((routineType) => (
                <div key={routineType}>
                  <h4 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                    {routineType === 'morningRoutine' ? 'Morning' : 'Evening'} Routine
                  </h4>
                  <ol className="list-decimal list-inside mb-6">
                    {routine[routineType].map((step, index) => (
                      <li key={index} className={`mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        {isEditMode ? (
                          <div className="flex flex-col sm:flex-row items-start sm:items-center">
                            <input
                              type="text"
                              value={step.product}
                              onChange={(e) => {
                                const updatedRoutine = {...routine};
                                updatedRoutine[routineType][index].product = e.target.value;
                                setRoutine(updatedRoutine);
                              }}
                              className={`mb-2 sm:mb-0 sm:mr-2 p-1 rounded w-full sm:w-auto ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                            />
                            <input
                              type="text"
                              value={step.instructions}
                              onChange={(e) => {
                                const updatedRoutine = {...routine};
                                updatedRoutine[routineType][index].instructions = e.target.value;
                                setRoutine(updatedRoutine);
                              }}
                              className={`p-1 rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                            />
                          </div>
                        ) : (
                          <span>
                            <strong>{step.product}:</strong> {step.instructions}
                          </span>
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
              
              <h4 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Weekly Treatments</h4>
              <ul className="list-disc list-inside mb-6">
                {routine.weeklyTreatments.map((treatment, index) => (
                  <li key={index} className={`mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    {isEditMode ? (
                      <div className="flex flex-col sm:flex-row items-start sm:items-center">
                        <input
                          type="text"
                          value={treatment.day}
                          onChange={(e) => {
                            const updatedRoutine = {...routine};
                            updatedRoutine.weeklyTreatments[index].day = e.target.value;
                            setRoutine(updatedRoutine);
                          }}
                          className={`mb-2 sm:mb-0 sm:mr-2 p-1 rounded w-full sm:w-auto ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                        />
                        <input
                          type="text"
                          value={treatment.treatment}
                          onChange={(e) => {
                            const updatedRoutine = {...routine};
                            updatedRoutine.weeklyTreatments[index].treatment = e.target.value;
                            setRoutine(updatedRoutine);
                          }}
                          className={`mb-2 sm:mb-0 sm:mr-2 p-1 rounded w-full sm:w-auto ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                        />
                        <input
                          type="text"
                          value={treatment.instructions}
                          onChange={(e) => {
                            const updatedRoutine = {...routine};
                            updatedRoutine.weeklyTreatments[index].instructions = e.target.value;
                            setRoutine(updatedRoutine);
                          }}
                          className={`p-1 rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                        />
                      </div>
                    ) : (
                      <span>
                        <strong>{treatment.day} - {treatment.treatment}:</strong> {treatment.instructions}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
              
              <h4 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Product Recommendations</h4>
              <ul className="list-disc list-inside mb-6">
                {Object.entries(routine.productRecommendations).map(([product, recommendation], index) => (
                  <li key={index} className={`mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    {isEditMode ? (
                      <div className="flex flex-col sm:flex-row items-start sm:items-center">
                        <input
                          type="text"
                          value={product}
                          onChange={(e) => {
                            const updatedRoutine = {...routine};
                            const oldRecommendation = updatedRoutine.productRecommendations[product];
                            delete updatedRoutine.productRecommendations[product];
                            updatedRoutine.productRecommendations[e.target.value] = oldRecommendation;
                            setRoutine(updatedRoutine);
                          }}
                          className={`mb-2 sm:mb-0 sm:mr-2 p-1 rounded w-full sm:w-auto ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                        />
                        <input
                          type="text"
                          value={recommendation}
                          onChange={(e) => {
                            const updatedRoutine = {...routine};
                            updatedRoutine.productRecommendations[product] = e.target.value;
                            setRoutine(updatedRoutine);
                          }}
                          className={`p-1 rounded w-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                        />
                      </div>
                    ) : (
                      <span>
                        <strong>{product.charAt(0).toUpperCase() + product.slice(1)}:</strong> {recommendation}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
              
              <h4 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Lifestyle Recommendations</h4>
              <ul className="list-disc list-inside mb-6">
                {routine.lifestyleRecommendations.map((recommendation, index) => (
                  <li key={index} className={`mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    {isEditMode ? (
                      <input
                        type="text"
                        value={recommendation}
                        onChange={(e) => {
                          const updatedRoutine = {...routine};
                          updatedRoutine.lifestyleRecommendations[index] = e.target.value;
                          setRoutine(updatedRoutine);
                        }}
                        className={`w-full p-1 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                      />
                    ) : (
                      recommendation
                    )}
                  </li>
                ))}
              </ul>
              
              <h4 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Dietary Recommendations</h4>
              <ul className="list-disc list-inside mb-6">
                {routine.dietaryRecommendations.map((recommendation, index) => (
                  <li key={index} className={`mb-2 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    {isEditMode ? (
                      <input
                        type="text"
                        value={recommendation}
                        onChange={(e) => {
                          const updatedRoutine = {...routine};
                          updatedRoutine.dietaryRecommendations[index] = e.target.value;
                          setRoutine(updatedRoutine);
                        }}
                        className={`w-full p-1 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                      />
                    ) : (
                      recommendation
                    )}
                  </li>
                ))}
              </ul>
              
              <div className="mt-8 flex flex-col sm:flex-row sm:flex-wrap gap-4">
                {isEditMode ? (
                  <button
                    onClick={() => saveRoutineToFirestore(routine)}
                    disabled={loading || !currentUser}
                    className={`w-full sm:w-auto px-6 py-3 ${darkMode ? 'bg-green-600' : 'bg-green-500'} text-white rounded-lg text-lg font-semibold hover:bg-green-600 transition-colors flex items-center justify-center ${
                      loading || !currentUser ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? (
                      <>
                        <Loader size={18} className="animate-spin mr-2" />
                        Saving...
                      </>
                    ) : (
                      <>
                        <Save size={18} className="mr-2" />
                        Save Changes
                      </>
                    )}
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => setIsEditMode(true)}
                      className={`w-full sm:w-auto px-6 py-3 ${darkMode ? 'bg-blue-600' : 'bg-blue-500'} text-white rounded-lg text-lg font-semibold hover:bg-blue-600 transition-colors flex items-center justify-center`}
                    >
                      <Edit size={18} className="mr-2" />
                      Edit Routine
                    </button>
                    <button
                      onClick={() => saveRoutineToFirestore(routine)}
                      disabled={loading || !currentUser}
                      className={`w-full sm:w-auto px-6 py-3 ${darkMode ? 'bg-green-600' : 'bg-green-500'} text-white rounded-lg text-lg font-semibold hover:bg-green-600 transition-colors flex items-center justify-center ${
                        loading || !currentUser ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      <Save size={18} className="mr-2" />
                      Save Routine
                    </button>
                  </>
                )}
                <button
                  onClick={() => deleteRoutine(routine.id)}
                  className={`w-full sm:w-auto px-6 py-3 ${darkMode ? 'bg-red-600' : 'bg-red-500'} text-white rounded-lg text-lg font-semibold hover:bg-red-600 transition-colors flex items-center justify-center`}
                >
                  <Trash size={18} className="mr-2" />
                  Delete Routine
                </button>
                <button
                  onClick={() => {
                    setRoutine(null);
                    setCurrentStep(1);
                  }}
                  className={`w-full sm:w-auto px-6 py-3 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} ${darkMode ? 'text-white' : 'text-gray-800'} rounded-lg text-lg font-semibold hover:bg-opacity-80 transition-colors flex items-center justify-center`}
                >
                  <Plus size={18} className="mr-2" />
                  Create New Routine
                </button>
              </div>
            </div>
          ) : null;
            }
  };
  
  return (
    <div className={`p-4 max-w-4xl mx-auto ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      <h2 className="text-3xl font-bold mb-8">Professional Skincare Routine Builder</h2>
      
      {error && (
        <motion.div
          className="bg-red-500 text-white p-4 rounded mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          {error}
        </motion.div>
      )}
    
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`w-1/6 h-2 rounded ${
                index <= currentStep ? 'bg-avacasa-500' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
        <h3 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>
          {steps[currentStep].title}
        </h3>
      </div>
    
      {renderStepContent()}
    
      {(currentStep > 0 || (!hasSavedRoutine && currentStep === 0)) && currentStep < steps.length - 1 && (
        <div className="mt-8 flex justify-between">
          {currentStep > 0 && (
            <button
              onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
              className={`px-4 py-2 ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'} rounded-lg flex items-center`}
            >
              <ChevronLeft size={18} className="mr-2" />
              Previous
            </button>
          )}
          <button
            onClick={() => setCurrentStep(Math.min(steps.length - 1, currentStep + 1))}
            className={`px-4 py-2 ${darkMode ? 'bg-avacasa-600' : 'bg-avacasa-500'} text-white rounded-lg flex items-center ml-auto`}
          >
            Next
            <ChevronRight size={18} className="ml-2" />
          </button>
        </div>
      )}
    
      {currentStep === steps.length - 1 && !routine && (
        <div className="mt-8">
          <button
            onClick={handleSubmit}
            disabled={loading || !skinAnalysis}
            className={`px-6 py-3 ${darkMode ? 'bg-avacasa-600' : 'bg-avacasa-500'} text-white rounded-lg text-lg font-semibold hover:bg-avacasa-600 transition-colors flex items-center ${
              loading || !skinAnalysis ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? (
              <>
                <Loader size={18} className="animate-spin mr-2" />
                Generating...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                Create Routine
              </>
            )}
          </button>
        </div>
      )}
    
    <AnimatePresence>
        {saveSuccess && (
          <motion.div
            className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
          >
            Skincare routine saved successfully!
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SkincareRoutineBuilder;