import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return unsubscribe;
  }, []);

  const handleAuthStateChanged = async (user) => {
    if (user) {
      await updateUserData(user);
    } else {
      setCurrentUser(null);
      setDarkMode(false);
    }
    setLoading(false);
  };

  const updateUserData = async (user) => {
    const userDocRef = doc(db, 'users', user.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCurrentUser({ ...user, ...userData });
        setDarkMode(userData.darkMode || false);
      } else {
        const basicUserData = {
          uid: user.uid,
          email: user.email,
          darkMode: false,
        };
        await setDoc(userDocRef, basicUserData);
        setCurrentUser({ ...user, ...basicUserData });
      }

      // Set up real-time listener for user document changes
      const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
        const userData = doc.data();
        setCurrentUser(prevUser => ({ ...prevUser, ...userData }));
        setDarkMode(userData.darkMode || false);
      });

      return unsubscribeUser;
    } catch (error) {
      console.error("Error fetching user data:", error);
      setCurrentUser(user); // Fallback to just the auth user object
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await updateUserData(userCredential.user);
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setDarkMode(false);
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const toggleDarkMode = async () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      try {
        await updateDoc(userDocRef, { darkMode: newDarkMode });
      } catch (error) {
        console.error("Error updating dark mode preference:", error);
        // Revert the state if the update fails
        setDarkMode(!newDarkMode);
      }
    }
  };

  const updateUserProfile = async (updates) => {
    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);
      try {
        await updateDoc(userDocRef, updates);
        // Immediately update the local state
        setCurrentUser(prevUser => ({ ...prevUser, ...updates }));
      } catch (error) {
        console.error("Error updating user profile:", error);
        throw error;
      }
    }
  };

  const value = {
    currentUser,
    setCurrentUser, // Add this line
    loading,
    darkMode,
    login,
    logout,
    toggleDarkMode,
    updateUserProfile // Include this function in the context
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}