import React, { useState, useEffect } from 'react';
import { X, User, Camera, Save, MapPin } from 'lucide-react';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchWeatherForCity, fetchCitySuggestions } from '../weatherApi';

const UserProfile = ({ isOpen, onClose, darkMode, onSave, currentUser }) => {
  const { updateUserProfile } = useAuth();
  const [profile, setProfile] = useState({
    name: '',
    age: '',
    gender: '',
    stylePreferences: [],
    skinType: '',
    hairType: '',
    profileImage: null,
    city: '',
  });
  const [activeTab, setActiveTab] = useState('basic');
  const [weather, setWeather] = useState(null);
  const [cityInput, setCityInput] = useState('');
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const styleOptions = ['Chic', 'Bohemian', 'Minimalist', 'Vintage', 'Streetwear', 'Glamorous', 'Preppy', 'Edgy', 'Classic', 'Romantic'];
  const genders = ['Female', 'Male', 'Non-binary', 'Prefer not to say'];
  const skinTypes = [
    { value: 'dry', label: 'Dry' },
    { value: 'oily', label: 'Oily' },
    { value: 'combination', label: 'Combination' },
    { value: 'normal', label: 'Normal' },
    { value: 'sensitive', label: 'Sensitive' },
    { value: 'unsure', label: 'Unsure' }
  ];
  const hairTypes = ['Straight', 'Wavy', 'Curly', 'Coily', 'Not sure'];

  useEffect(() => {
    if (currentUser) {
      setProfile({
        name: currentUser.name || '',
        age: currentUser.age || '',
        gender: currentUser.gender || '',
        stylePreferences: currentUser.stylePreferences || [],
        skinType: currentUser.skinType || '',
        hairType: currentUser.hairType || '',
        profileImage: currentUser.profileImageUrl || null,
        city: currentUser.city || '',
      });
      setCityInput(currentUser.city || '');
      setSelectedCity(currentUser.selectedCity || null);
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedCity) {
      fetchWeatherForCity(selectedCity.lat, selectedCity.lon).then(weatherData => {
        if (weatherData) {
          setWeather(weatherData);
          setProfile(prev => ({ ...prev, city: weatherData.city }));
        } else {
          setWeather(null);
        }
      });
    }
  }, [selectedCity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleCityInputChange = async (e) => {
    const value = e.target.value;
    setCityInput(value);
    if (value.length > 2) {
      const suggestions = await fetchCitySuggestions(value);
      setCitySuggestions(suggestions);
    } else {
      setCitySuggestions([]);
    }
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setCityInput(`${city.name}, ${city.state || ''} ${city.country}`);
    setCitySuggestions([]);
  };

  const handleStylePreferenceChange = (preference) => {
    setProfile(prev => {
      const updatedPreferences = prev.stylePreferences.includes(preference)
        ? prev.stylePreferences.filter(p => p !== preference)
        : [...prev.stylePreferences, preference].slice(0, 3);
      return { ...prev, stylePreferences: updatedPreferences };
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(prev => ({ ...prev, profileImage: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!currentUser) return;
  
    try {
      let profileImageUrl = profile.profileImage;
      if (profile.profileImage && profile.profileImage.startsWith('data:image')) {
        const imageRef = ref(storage, `profileImages/${currentUser.uid}`);
        const response = await fetch(profile.profileImage);
        const blob = await response.blob();
        await uploadBytes(imageRef, blob);
        profileImageUrl = await getDownloadURL(imageRef);
      }
  
      const updatedProfile = {
        name: profile.name,
        age: profile.age,
        gender: profile.gender,
        stylePreferences: profile.stylePreferences,
        skinType: profile.skinType,
        hairType: profile.hairType,
        profileImageUrl,
        city: selectedCity ? `${selectedCity.name}, ${selectedCity.state || ''} ${selectedCity.country}` : '',
        selectedCity,
        weather: {
          temperature: weather?.temperature,
          description: weather?.description,
          city: weather?.city,
          country: weather?.country,
          forecast24h: weather?.forecast24h,
          forecastWeekly: weather?.forecastWeekly
        },
      };
  
      await updateUserProfile(updatedProfile);
      onSave(updatedProfile);
      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'basic':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={profile.name}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                placeholder="Your Name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Age</label>
              <input
                type="number"
                name="age"
                value={profile.age}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                placeholder="Your Age"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Gender</label>
              <select
                name="gender"
                value={profile.gender}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
              >
                <option value="">Select Gender</option>
                {genders.map(gender => (
                  <option key={gender} value={gender}>{gender}</option>
                ))}
              </select>
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-500 mb-1">City</label>
              <input
                type="text"
                value={cityInput}
                onChange={handleCityInputChange}
                className={`w-full px-3 py-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                placeholder="Search for your city"
              />
              {citySuggestions.length > 0 && (
                <ul className={`absolute z-10 w-full mt-1 rounded-md shadow-lg ${darkMode ? 'bg-gray-700' : 'bg-white'}`}>
                  {citySuggestions.map((city, index) => (
                    <li
                      key={index}
                      onClick={() => handleCitySelect(city)}
                      className={`px-4 py-2 cursor-pointer ${darkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-100'}`}
                    >
                      {city.name}, {city.state} {city.country}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </motion.div>
        );
      case 'style':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-2">Style Preferences (Select up to 3)</label>
              <div className="flex flex-wrap gap-2">
                {styleOptions.map(style => (
                  <motion.button
                    key={style}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleStylePreferenceChange(style)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                      profile.stylePreferences.includes(style)
                        ? 'bg-avacasa-600 text-white'
                        : darkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {style}
                  </motion.button>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Skin Type</label>
              <select
                name="skinType"
                value={profile.skinType}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
              >
                <option value="">Select Skin Type</option>
                {skinTypes.map(type => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Hair Type</label>
              <select
                name="hairType"
                value={profile.hairType}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
              >
                <option value="">Select Hair Type</option>
                {hairTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="min-h-screen flex items-center justify-center p-4">
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              className={`w-full max-w-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-xl overflow-hidden`}
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-3xl font-bold text-avacasa-600">Your Style Profile</h2>
                  <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X size={24} />
                  </button>
                </div>
                <div className="flex justify-center mb-8">
                  <div className="relative group">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="w-32 h-32 rounded-full overflow-hidden bg-gray-200 shadow-lg"
                    >
                      {profile.profileImage ? (
                        <img src={profile.profileImage} alt="Profile" className="w-full h-full object-cover" />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <User size={64} className="text-gray-400" />
                        </div>
                      )}
                    </motion.div>
                    <label htmlFor="profile-image-upload" className="absolute bottom-0 right-0 bg-avacasa-500 text-white p-2 rounded-full cursor-pointer shadow-md hover:bg-avacasa-600 transition-colors">
                      <Camera size={20} />
                    </label>
                    <input
                      id="profile-image-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <div className="flex space-x-4 border-b border-gray-300">
                    <button
                      onClick={() => setActiveTab('basic')}
                      className={`py-2 px-4 font-medium ${activeTab === 'basic' ? 'text-avacasa-600 border-b-2 border-avacasa-600' : 'text-gray-500'}`}
                    >
                      Basic Info
                    </button>
                    <button
                      onClick={() => setActiveTab('style')}
                      className={`py-2 px-4 font-medium ${activeTab === 'style' ? 'text-avacasa-600 border-b-2 border-avacasa-600' : 'text-gray-500'}`}
                    >
                      Style Preferences
                    </button>
                  </div>
                </div>
                {renderTabContent()}
              </div>
              <div className={`px-6 py-4 ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleSave}
                  className="w-full flex items-center justify-center px-6 py-3 rounded-full bg-avacasa-600 hover:bg-avacasa-700 text-white transition-colors duration-300 text-lg font-medium"
                >
                  <Save size={20} className="mr-2" />
                  Save Profile
                </motion.button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UserProfile;