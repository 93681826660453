import React, { useState } from 'react';
import { X } from 'lucide-react';

const StyleHoroscope = ({ onClose, darkMode, onSubmit }) => {
  const [zodiacSign, setZodiacSign] = useState('');
  const [occasion, setOccasion] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ zodiacSign, occasion, date });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-96 max-w-full`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Style Horoscope</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Zodiac Sign</label>
            <select
              value={zodiacSign}
              onChange={(e) => setZodiacSign(e.target.value)}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            >
              <option value="">Select your zodiac sign</option>
              {['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'].map((sign) => (
                <option key={sign} value={sign}>{sign}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Special Occasion (optional)</label>
            <input
              type="text"
              value={occasion}
              onChange={(e) => setOccasion(e.target.value)}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              placeholder="E.g., Wedding, Job Interview, Date Night"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors"
          >
            Get Style Horoscope
          </button>
        </form>
      </div>
    </div>
  );
};

export default StyleHoroscope;