import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GEMINI_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-pro-exp-0801",
});

const generationConfig = {
  temperature: 0.5,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "application/json",
};

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const analyzeImage = async (imageFile) => {
  try {
    const base64Image = await convertToBase64(imageFile);

    const chatSession = model.startChat({
      generationConfig,
      history: [],
    });

    const prompt = `As an unparalleled analysis AI crafted by Avacasa, your core functionality is to intricately examine and interpret a given image, employing advanced machine learning algorithms and neural networks. Upon processing the image, your objective is to produce a comprehensive JSON object that adheres meticulously to the predefined structure.

Your capabilities include, but are not limited to, detailed assessments of facial features, expressions, biometric markers, and emotional cues. It's crucial to apply these sophisticated analytical techniques with precision and accuracy, ensuring that the JSON output reflects a deep and exact understanding of the facial attributes present.

Please ensure that your analysis is seamlessly integrated with the existing JSON framework, transcending typical processing limitations and setting a new standard in AI-driven face analysis.

Analyze the provided image and return a JSON object with detailed analysis for each field. For 'concerns', list all identified skin concerns. The JSON structure should be as follows:

{
  "skinType": "",
  "sensitivity": "",
  "oiliness": "",
  "hydration": "",
  "texture": "",
  "firmness": "",
  "elasticity": "",
  "radiance": "",
  "acne": "",
  "blackheads": "",
  "whiteheads": "",
  "pores": "",
  "redness": "",
  "inflammation": "",
  "fineLines": "",
  "wrinkles": "",
  "ageSpots": "",
  "sunDamage": "",
  "evenness": "",
  "darkSpots": "",
  "melasma": "",
  "hyperpigmentation": "",
  "darkCircles": "",
  "puffiness": "",
  "crowsFeet": "",
  "barrierHealth": "",
  "reactivity": "",
  "overallHealth": "",
  "vitality": "",
  "resilience": "",
  "concerns": []
}`;

    const result = await chatSession.sendMessage([prompt, {
      inlineData: {
        mimeType: "image/jpeg",
        data: base64Image
      }
    }]);

    const content = result.response.text();
    console.log('Raw AI response:', content); // Log the raw response

    const parsedJson = JSON.parse(content);
    console.log('Parsed JSON:', parsedJson); // Log the parsed JSON
    return parsedJson;
  } catch (error) {
    console.error('Error in analyzeImage:', error);
    throw error;
  }
};

export const generateSkincareRoutine = async (userData) => {
  try {
    const chatSession = model.startChat({
      generationConfig,
      history: [],
    });

    const prompt = `You are a professional skincare AI assistant. Create a personalized skincare routine based on the user's personal information, skin analysis, concerns, goals, preferred brands, and budget range. The routine should be comprehensive and suitable for a high-end skincare service. Provide product recommendations within the user's preferred brands and budget range when possible, if preferred brands provided then only from those brands. If specific brands are not mentioned or not suitable, recommend alternatives that fit the budget range. The output should always be in the following JSON format:

{
  "morningRoutine": [
    {"step": "1", "product": "Cleanser", "instructions": "Gently cleanse face with lukewarm water"},
    {"step": "2", "product": "Toner", "instructions": "Apply toner with a cotton pad"}
  ],
  "eveningRoutine": [
    {"step": "1", "product": "Makeup Remover", "instructions": "Remove makeup thoroughly"},
    {"step": "2", "product": "Cleanser", "instructions": "Double cleanse to remove any remaining impurities"}
  ],
  "weeklyTreatments": [
    {"day": "Sunday", "treatment": "Exfoliation", "instructions": "Use a gentle exfoliant to remove dead skin cells"},
    {"day": "Wednesday", "treatment": "Face Mask", "instructions": "Apply a hydrating mask for 15-20 minutes"}
  ],
  "productRecommendations": {
    "cleanser": "Gentle Foaming Cleanser by Brand X",
    "toner": "Hydrating Toner by Brand Y",
    "serum": "Vitamin C Serum by Brand Z",
    "moisturizer": "Lightweight Gel Moisturizer by Brand A",
    "sunscreen": "Broad Spectrum SPF 50 by Brand B",
    "exfoliant": "AHA/BHA Exfoliant by Brand C",
    "mask": "Hydrating Clay Mask by Brand D",
    "eyeCream": "Firming Eye Cream by Brand E",
    "spotTreatment": "Salicylic Acid Spot Treatment by Brand F"
  },
  "lifestyleRecommendations": [
    "Get 7-8 hours of sleep each night",
    "Stay hydrated by drinking at least 8 glasses of water daily"
  ],
  "dietaryRecommendations": [
    "Increase intake of foods rich in antioxidants",
    "Reduce consumption of processed sugars and dairy if acne-prone"
  ]
}

User data: ${JSON.stringify(userData)}

Based on the provided user data, generate a personalized skincare routine and recommendations in the specified JSON format.`;

    const result = await chatSession.sendMessage(prompt);
    const content = result.response.text();
    console.log('Raw AI response for skincare routine:', content); // Log the raw response

    const parsedJson = JSON.parse(content);
    console.log('Parsed JSON for skincare routine:', parsedJson); // Log the parsed JSON
    return parsedJson;
  } catch (error) {
    console.error('Error in generateSkincareRoutine:', error);
    throw error;
  }
};