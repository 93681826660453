import React, { useState, useEffect } from 'react';
import { X, Upload, Edit2 } from 'lucide-react';

const SkinAnalysis = ({ onClose, darkMode, onImageSelect, currentUser, onUpdateProfile }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [skinType, setSkinType] = useState(currentUser.skinType || '');
  const [focusArea, setFocusArea] = useState('');
  const [concerns, setConcerns] = useState('');
  const [editingSkinType, setEditingSkinType] = useState(false);

  const skinTypes = [
    { value: 'dry', label: 'Dry' },
    { value: 'oily', label: 'Oily' },
    { value: 'combination', label: 'Combination' },
    { value: 'normal', label: 'Normal' },
    { value: 'sensitive', label: 'Sensitive' },
    { value: 'unsure', label: 'Unsure' }
  ];

  useEffect(() => {
    setSkinType(currentUser.skinType || '');
  }, [currentUser.skinType]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmSelection = async () => {
    if (selectedImage && previewUrl) {
      if (skinType !== currentUser.skinType) {
        await onUpdateProfile({ skinType });
      }
      onImageSelect(selectedImage, previewUrl, {
        skinType,
        focusArea,
        concerns
      });
      onClose();
    }
  };

  const renderSkinTypeField = () => {
    if (!editingSkinType) {
      const selectedType = skinTypes.find(type => type.value === skinType);
      return (
        <div className="flex items-center justify-between">
          <span>{selectedType ? selectedType.label : 'Not set'}</span>
          <button
            onClick={() => setEditingSkinType(true)}
            className={`p-1 rounded-full ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
          >
            <Edit2 size={18} />
          </button>
        </div>
      );
    }

    return (
      <select
        value={skinType}
        onChange={(e) => {
          setSkinType(e.target.value);
          setEditingSkinType(false);
        }}
        className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
      >
        <option value="">Select skin type</option>
        {skinTypes.map(type => (
          <option key={type.value} value={type.value}>{type.label}</option>
        ))}
      </select>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-96 max-w-full`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Skin Analysis</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>
        <div className="mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="skin-analysis-upload"
          />
          <label
            htmlFor="skin-analysis-upload"
            className={`flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer ${
              darkMode ? 'border-gray-600 hover:border-gray-500' : 'border-gray-300 hover:border-gray-400'
            }`}
          >
            {previewUrl ? (
              <img src={previewUrl} alt="Selected" className="max-h-full rounded object-contain" />
            ) : (
              <div className="flex flex-col items-center">
                <Upload size={24} className={darkMode ? 'text-gray-400' : 'text-gray-600'} />
                <span className="mt-2">Click to upload an image</span>
              </div>
            )}
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Your skin type:</label>
          {renderSkinTypeField()}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Is there a specific area you want to focus on?</label>
          <input
            type="text"
            value={focusArea}
            onChange={(e) => setFocusArea(e.target.value)}
            placeholder="e.g., forehead, cheeks, chin"
            className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Any specific skin concerns?</label>
          <textarea
            value={concerns}
            onChange={(e) => setConcerns(e.target.value)}
            placeholder="e.g., acne, wrinkles, dark spots"
            className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
            rows="3"
          />
        </div>
        <button
          onClick={handleConfirmSelection}
          disabled={!selectedImage}
          className={`w-full py-2 px-4 rounded-lg ${
            selectedImage
              ? 'bg-avacasa-500 text-white hover:bg-avacasa-600'
              : `${darkMode ? 'bg-gray-700 text-gray-400' : 'bg-gray-200 text-gray-500'} cursor-not-allowed`
          } transition-colors`}
        >
          Confirm Selection
        </button>
      </div>
    </div>
  );
};

export default SkinAnalysis;