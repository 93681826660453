import React, { useState } from 'react';
import { X, Upload } from 'lucide-react';

const HairAnalysis = ({ onClose, darkMode, onImageSelect, currentUser }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [hairType, setHairType] = useState('');
  const [hairConcerns, setHairConcerns] = useState('');
  const [currentHairStyle, setCurrentHairStyle] = useState('');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmSelection = () => {
    if (selectedImage && previewUrl) {
      onImageSelect(selectedImage, previewUrl, {
        hairType,
        hairConcerns,
        currentHairStyle
      });
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-96 max-w-full`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Hair Analysis</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>
            <X size={24} />
          </button>
        </div>
        <div className="mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="hair-analysis-upload"
          />
          <label
            htmlFor="hair-analysis-upload"
            className={`flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer ${
              darkMode ? 'border-gray-600 hover:border-gray-500' : 'border-gray-300 hover:border-gray-400'
            }`}
          >
            {previewUrl ? (
              <img src={previewUrl} alt="Selected" className="max-h-full rounded object-contain" />
            ) : (
              <div className="flex flex-col items-center">
                <Upload size={24} className={darkMode ? 'text-gray-400' : 'text-gray-600'} />
                <span className="mt-2">Click to upload an image</span>
              </div>
            )}
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Your hair type:</label>
          <input
            type="text"
            value={hairType}
            onChange={(e) => setHairType(e.target.value)}
            placeholder="e.g., straight, wavy, curly, coily"
            className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Hair concerns:</label>
          <input
            type="text"
            value={hairConcerns}
            onChange={(e) => setHairConcerns(e.target.value)}
            placeholder="e.g., dryness, frizz, damage, thinning"
            className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Current hairstyle:</label>
          <input
            type="text"
            value={currentHairStyle}
            onChange={(e) => setCurrentHairStyle(e.target.value)}
            placeholder="e.g., long layers, bob, pixie cut"
            className={`w-full p-2 rounded ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
          />
        </div>
        <button
          onClick={handleConfirmSelection}
          disabled={!selectedImage}
          className={`w-full py-2 px-4 rounded-lg ${
            selectedImage
              ? 'bg-avacasa-500 text-white hover:bg-avacasa-600'
              : `${darkMode ? 'bg-gray-700 text-gray-400' : 'bg-gray-200 text-gray-500'} cursor-not-allowed`
          } transition-colors`}
        >
          Confirm Selection
        </button>
      </div>
    </div>
  );
};

export default HairAnalysis;