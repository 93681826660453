import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { db, storage } from '../firebase';
import { collection, addDoc, query, getDocs, deleteDoc, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { analyzeProduct } from '../services/productAnalysis';
import { X, Plus, Loader, Edit, Camera, Save, Search, ChevronUp, ChevronDown, Grid, List } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';


const defaultCategories = ['Clothing', 'Shoes', 'Accessories', 'Makeup', 'Skincare'];

const clothingFields = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'description', label: 'Description', type: 'textarea' },
  { name: 'color', label: 'Color', type: 'text' },
  { name: 'category', label: 'Category', type: 'select' },
  { name: 'subcategory', label: 'Subcategory', type: 'text' },
  { name: 'brand', label: 'Brand', type: 'text' },
  { name: 'material', label: 'Material', type: 'text' },
  { name: 'style', label: 'Style', type: 'text' },
  { name: 'occasion', label: 'Occasion', type: 'text' },
  { name: 'season', label: 'Season', type: 'multiselect', options: ['Spring', 'Summer', 'Fall', 'Winter'] },
  { name: 'careInstructions', label: 'Care Instructions', type: 'textarea' },
  { name: 'size', label: 'Size', type: 'text' },
  { name: 'fit', label: 'Fit', type: 'select', options: ['Tight', 'Fitted', 'Regular', 'Loose', 'Oversized'] },
  { name: 'condition', label: 'Condition', type: 'select', options: ['New', 'Like New', 'Good', 'Fair', 'Poor'] },
  { name: 'purchaseDate', label: 'Purchase Date', type: 'date' },
  { name: 'lastWornDate', label: 'Last Worn Date', type: 'date' },
  { name: 'timesWorn', label: 'Times Worn', type: 'number' },
  { name: 'priceApprox', label: 'Approx. Price', type: 'text' },
];

const makeupFields = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'description', label: 'Description', type: 'textarea' },
  { name: 'brand', label: 'Brand', type: 'text' },
  { name: 'category', label: 'Category', type: 'select', options: ['Foundation', 'Concealer', 'Eyeshadow', 'Mascara', 'Lipstick', 'Blush', 'Bronzer', 'Other'] },
  { name: 'subcategory', label: 'Subcategory', type: 'text' },
  { name: 'shade', label: 'Shade', type: 'text' },
  { name: 'finish', label: 'Finish', type: 'select', options: ['Matte', 'Satin', 'Dewy', 'Metallic', 'Glitter', 'Other'] },
  { name: 'coverage', label: 'Coverage', type: 'select', options: ['Sheer', 'Light', 'Medium', 'Full'] },
  { name: 'openedDate', label: 'Opened Date', type: 'date' },
  { name: 'purchaseDate', label: 'Purchase Date', type: 'date' },
  { name: 'priceApprox', label: 'Approx. Price', type: 'text' },
  { name: 'ingredients', label: 'Key Ingredients', type: 'textarea' },
];

const skincareFields = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'description', label: 'Description', type: 'textarea' },
  { name: 'brand', label: 'Brand', type: 'text' },
  { name: 'category', label: 'Category', type: 'select', options: ['Cleanser', 'Toner', 'Serum', 'Moisturizer', 'Sunscreen', 'Mask', 'Exfoliant', 'Other'] },
  { name: 'subcategory', label: 'Subcategory', type: 'text' },
  { name: 'skinType', label: 'Skin Type', type: 'multiselect', options: ['Normal', 'Dry', 'Oily', 'Combination', 'Sensitive'] },
  { name: 'concerns', label: 'Skin Concerns', type: 'multiselect', options: ['Acne', 'Aging', 'Dryness', 'Dullness', 'Uneven Texture', 'Hyperpigmentation', 'Redness'] },
  { name: 'ingredients', label: 'Key Ingredients', type: 'textarea' },
  { name: 'size', label: 'Size', type: 'text' },
  { name: 'openedDate', label: 'Opened Date', type: 'date' },
  { name: 'purchaseDate', label: 'Purchase Date', type: 'date' },
  { name: 'priceApprox', label: 'Approx. Price', type: 'text' },
  { name: 'frequency', label: 'Usage Frequency', type: 'select', options: ['Daily', 'Weekly', 'Bi-Weekly', 'Monthly', 'As Needed'] },
];

const Wardrobe = () => {
  const { currentUser, darkMode } = useAuth();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState(defaultCategories);
  const [isLoading, setIsLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [isClosing, setIsClosing] = useState(false);
  const [activeCategory, setActiveCategory] = useState('All');
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [newProduct, setNewProduct] = useState({});
  const [editingProduct, setEditingProduct] = useState(null);
  const [productType, setProductType] = useState('clothing');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('category');
  const [sortOrder, setSortOrder] = useState('asc');
  const [viewMode, setViewMode] = useState('grid');
  const [showAllCategories, setShowAllCategories] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = onSnapshot(
        query(collection(db, `users/${currentUser.uid}/wardrobe`)),
        (snapshot) => {
          const fetchedProducts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setProducts(fetchedProducts);
        }
      );

      fetchCategories();

      return () => unsubscribe();
    }
  }, [currentUser]);

  const fetchCategories = async () => {
    if (!currentUser) return;

    const q = query(collection(db, `users/${currentUser.uid}/categories`));
    const querySnapshot = await getDocs(q);
    const fetchedCategories = querySnapshot.docs.map(doc => doc.data().name);
    setCategories([...new Set([...defaultCategories, ...fetchedCategories])]);
  };


  const removeBackground = async (imageUrl) => {
    const subscriptionKey = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY;
    const endpoint = process.env.REACT_APP_AZURE_ENDPOINT;
      
    try {
      const response = await axios({
        method: 'post',
        url: `${endpoint}/computervision/imageanalysis:segment?api-version=2023-02-01-preview&mode=backgroundRemoval`,
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': subscriptionKey
        },
        data: {
          url: imageUrl
        },
        responseType: 'arraybuffer'
      });
  
      const base64Image = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      return `data:image/png;base64,${base64Image}`;
    } catch (error) {
      console.error('Error removing background:', error);
      return null;
    }
  };
  const handleProductUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setIsLoading(true);
    try {
      const imageUrl = await uploadImage(file);
      const productInfo = await analyzeProduct(imageUrl);
      
      // Remove background
      const processedImageUrl = await removeBackground(imageUrl);
      
      setNewProduct({ ...productInfo, imageUrl: processedImageUrl || imageUrl });
      setProductType(productInfo.type || 'clothing');
      setIsAddingProduct(true);
    } catch (error) {
      console.error('Error uploading product:', error);
      // Handle error (show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImageToStorage = async (imageDataUrl) => {
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();
    const imageRef = ref(storage, `wardrobe/${currentUser.uid}/${Date.now()}_product.png`);
    await uploadBytes(imageRef, blob);
    return getDownloadURL(imageRef);
  };

  const uploadImage = async (file) => {
    const imageRef = ref(storage, `wardrobe/${currentUser.uid}/${Date.now()}_${file.name}`);
    await uploadBytes(imageRef, file);
    return getDownloadURL(imageRef);
  };

const saveProduct = async () => {
  setIsLoading(true);
  setErrorMessage('');

  try {
    let imageUrl = newProduct.imageUrl;

    // If the imageUrl is a data URL, upload it to Firebase Storage
    if (imageUrl && imageUrl.startsWith('data:')) {
      imageUrl = await uploadImageToStorage(imageUrl);
    }

    const productToSave = {
      ...newProduct,
      imageUrl, // Use the Storage URL instead of the data URL
      type: productType,
      createdAt: new Date(),
    };

    let savedProductId;

    if (editingProduct) {
      await updateDoc(doc(db, `users/${currentUser.uid}/wardrobe`, editingProduct.id), productToSave);
      savedProductId = editingProduct.id;
    } else {
      const docRef = await addDoc(collection(db, `users/${currentUser.uid}/wardrobe`), productToSave);
      savedProductId = docRef.id;
    }

    // Add category if it's new
    if (!categories.includes(newProduct.category)) {
      await addDoc(collection(db, `users/${currentUser.uid}/categories`), { name: newProduct.category });
      setCategories(prevCategories => [...prevCategories, newProduct.category]);
    }

    // Show success message
    setSaveSuccess(true);

    // Animate closing the modal
    setIsClosing(true);

    // Wait for animation to complete before fully closing
    setTimeout(() => {
      setIsAddingProduct(false);
      setEditingProduct(null);
      setNewProduct({});
      setIsClosing(false);
    }, 300);

  } catch (error) {
    console.error('Error saving product:', error);
    setErrorMessage('Failed to save product. Please try again.');
  } finally {
    setIsLoading(false);
  }
};
      
  const deleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, `users/${currentUser.uid}/wardrobe`, productId));
    } catch (error) {
      console.error('Error deleting product:', error);
      // Handle error (show error message to user)
    }
  };

  const editProduct = (product) => {
    setEditingProduct(product);
    setNewProduct(product);
    setProductType(product.type || 'clothing');
    setIsAddingProduct(true);
  };

  const getFieldsForProductType = (type) => {
    switch (type) {
      case 'clothing':
        return clothingFields;
      case 'makeup':
        return makeupFields;
      case 'skincare':
        return skincareFields;
      default:
        return clothingFields;
    }
  };

  const renderField = (field) => {
    const baseInputClass = `w-full p-2 rounded border ${
      darkMode
        ? 'bg-gray-700 text-white border-gray-600 focus:border-blue-500'
        : 'bg-gray-50 text-gray-800 border-gray-300 focus:border-blue-500'
    } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors`;
  
    switch (field.type) {
      case 'textarea':
        return (
          <textarea
            value={newProduct[field.name] || ''}
            onChange={(e) => setNewProduct({ ...newProduct, [field.name]: e.target.value })}
            className={`${baseInputClass} h-24`}
          />
        );
      case 'select':
        return (
          <select
            value={newProduct[field.name] || ''}
            onChange={(e) => setNewProduct({ ...newProduct, [field.name]: e.target.value })}
            className={baseInputClass}
          >
            <option value="">Select {field.label}</option>
            {field.options ? field.options.map(option => (
              <option key={option} value={option}>{option}</option>
            )) : categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        );
      case 'multiselect':
        return (
          <div className="flex flex-wrap gap-2">
            {field.options.map(option => (
              <label key={option} className={`flex items-center ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                <input
                  type="checkbox"
                  checked={newProduct[field.name]?.includes(option) || false}
                  onChange={(e) => {
                    const updatedValue = e.target.checked
                      ? [...(newProduct[field.name] || []), option]
                      : (newProduct[field.name] || []).filter(item => item !== option);
                    setNewProduct({ ...newProduct, [field.name]: updatedValue });
                  }}
                  className="mr-2 form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return (
          <input
            type={field.type}
            value={newProduct[field.name] || ''}
            onChange={(e) => setNewProduct({ ...newProduct, [field.name]: e.target.value })}
            className={baseInputClass}
          />
        );
    }
  };
  
  const filteredAndSortedProducts = products
    .filter(product => 
      (activeCategory === 'All' || product.category === activeCategory || 
       (activeCategory === 'Makeup' && product.type === 'makeup') ||
       (activeCategory === 'Skincare' && product.type === 'skincare')) &&
      ((product.name && product.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
       (product.brand && product.brand.toLowerCase().includes(searchTerm.toLowerCase())))
    )
    .sort((a, b) => {
      if (sortBy === 'category') {
        return sortOrder === 'asc' 
          ? (a.category || '').localeCompare(b.category || '') 
          : (b.category || '').localeCompare(a.category || '');
      } else if (sortBy === 'brand') {
        return sortOrder === 'asc' 
          ? (a.brand || '').localeCompare(b.brand || '') 
          : (b.brand || '').localeCompare(a.brand || '');
      }
      return 0;
    });

  const ProductCard = ({ product }) => (
    <motion.div
      layoutId={`product-${product.id}`}
      className={`border rounded-lg p-4 relative ${darkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'} shadow-lg`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <img src={product.imageUrl} alt={product.name || 'Product'} className="w-full aspect-square object-cover rounded-md mb-4" />
      <h3 className={`font-semibold text-lg mb-1 ${darkMode ? 'text-white' : 'text-gray-800'}`}>{product.name || 'Unnamed Product'}</h3>
      <p className={`text-sm mb-1 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>{product.brand || 'No Brand'}</p>
      <p className={`text-xs mb-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{product.category || 'Uncategorized'}</p>
      <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{product.priceApprox || 'Price not set'}</p>
      <div className="absolute top-2 right-2 flex space-x-1">
        <button
          onClick={() => editProduct(product)}
          className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 transition-colors"
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => deleteProduct(product.id)}
          className="bg-red-500 text-white rounded-full p-2 hover:bg-red-600 transition-colors"
        >
          <X size={16} />
        </button>
      </div>
    </motion.div>
  );

  const ProductListItem = ({ product }) => (
    <motion.div
      layoutId={`product-${product.id}`}
      className={`flex items-center border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'} py-4`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <img src={product.imageUrl} alt={product.name || 'Product'} className="w-16 h-16 object-cover rounded-md mr-4" />
      <div className="flex-grow">
        <h3 className={`font-semibold ${darkMode ? 'text-white' : 'text-gray-800'}`}>{product.name || 'Unnamed Product'}</h3>
        <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>{product.brand || 'No Brand'}</p>
        <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{product.category || 'Uncategorized'}</p>
      </div>
      <p className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'} mr-4`}>{product.priceApprox || 'Price not set'}</p>
      <div className="flex space-x-1">
        <button
          onClick={() => editProduct(product)}
          className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 transition-colors"
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => deleteProduct(product.id)}
          className="bg-red-500 text-white rounded-full p-2 hover:bg-red-600 transition-colors"
        >
          <X size={16} />
        </button>
      </div>
    </motion.div>
  );

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-center">
        <div className="flex items-center space-x-2">
          <input
            type="file"
            accept="image/*"
            onChange={handleProductUpload}
            className="hidden"
            id="product-upload"
          />
          <label
            htmlFor="product-upload"
            className="inline-flex items-center px-4 py-2 bg-avacasa-500 text-white rounded-md cursor-pointer hover:bg-avacasa-600 transition-colors"
          >
            <Camera size={18} className="mr-2" />
            Add Product
          </label>
        </div>
      </div>

      <div className="mb-6 flex flex-col sm:flex-row justify-between items-center">
        <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`w-full pl-10 pr-4 py-2 rounded-full ${
              darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
            } border ${darkMode ? 'border-gray-600' : 'border-gray-300'}`}
          />
          <Search size={18} className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
        </div>
        <div className="flex items-center space-x-2">
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className={`p-2 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'} border ${darkMode ? 'border-gray-600' : 'border-gray-300'}`}
          >
            <option value="category">Category</option>
            <option value="brand">Brand</option>
          </select>
          <button
            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
            className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-opacity-80 transition-colors`}
          >
            {sortOrder === 'asc' ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
          </button>
          <button
            onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
            className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-opacity-80 transition-colors`}
          >
            {viewMode === 'grid' ? <List size={18} /> : <Grid size={18} />}
          </button>
        </div>
      </div>

      <div className={`mb-6 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg p-4`}>
        <div className="flex items-center justify-between mb-2">
          <h3 className={`font-semibold ${darkMode ? 'text-white' : 'text-gray-800'}`}>Categories</h3>
          <button
            onClick={() => setShowAllCategories(!showAllCategories)}
            className={`text-sm ${darkMode ? 'text-avacasa-400' : 'text-avacasa-600'} hover:underline`}
          >
            {showAllCategories ? 'Show Less' : 'Show All'}
          </button>
        </div>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setActiveCategory('All')}
            className={`px-3 py-1 rounded-full ${
              activeCategory === 'All'
                ? 'bg-avacasa-500 text-white'
                : darkMode 
                  ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            } transition-colors`}
          >
            All
          </button>
          {categories.slice(0, showAllCategories ? categories.length : 5).map(category => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-3 py-1 rounded-full ${
                activeCategory === category
                  ? 'bg-avacasa-500 text-white'
                  : darkMode 
                    ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } transition-colors`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {isLoading && (
        <div className="flex justify-center items-center">
          <Loader className="animate-spin" size={24} />
          <span className="ml-2">Analyzing product...</span>
        </div>
      )}

      <AnimatePresence>
        {viewMode === 'grid' ? (
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {filteredAndSortedProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        ) : (
          <div className="space-y-4">
            {filteredAndSortedProducts.map((product) => (
              <ProductListItem key={product.id} product={product} />
            ))}
          </div>
        )}
      </AnimatePresence>

      {isAddingProduct && (
  <AnimatePresence>
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto`}
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>
          {editingProduct ? 'Edit Product' : 'Add New Product'}
        </h2>
        <div className="mb-4">
          <label className={`block mb-1 ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>Product Type</label>
          <select
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            className={`w-full p-2 rounded ${
              darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'
            }`}
          >
            <option value="clothing">Clothing</option>
            <option value="makeup">Makeup</option>
            <option value="skincare">Skincare</option>
          </select>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {getFieldsForProductType(productType).map(field => (
            <div key={field.name}>
              <label className={`block mb-1 ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>{field.label}</label>
              {renderField(field)}
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end space-x-2">
          <button
            onClick={() => {
              setIsClosing(true);
              setTimeout(() => {
                setIsAddingProduct(false);
                setEditingProduct(null);
                setNewProduct({});
                setIsClosing(false);
              }, 300);
            }}
            className={`px-4 py-2 rounded ${
              darkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            } transition-colors`}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={saveProduct}
            className="px-4 py-2 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors flex items-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader size={18} className="animate-spin mr-2" />
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                Save Product
              </>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  </AnimatePresence>
)}

<AnimatePresence>
  {saveSuccess}
</AnimatePresence>

<AnimatePresence>
  {errorMessage && (
    <motion.div
      className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded shadow"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}
    >
      {errorMessage}
    </motion.div>
  )}
</AnimatePresence>
</div>
);
};

export default Wardrobe;
