import React, { useState, useEffect } from 'react';
import { X, Edit, Trash, Save, ChevronDown, ChevronUp } from 'lucide-react';

const ManageMemories = ({ isOpen, onClose, memories, onUpdateMemory, onDeleteMemory, onDeleteAllMemories, darkMode }) => {
  const [editingMemory, setEditingMemory] = useState(null);
  const [editedKey, setEditedKey] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const [expandedMemories, setExpandedMemories] = useState({});
  const [localMemories, setLocalMemories] = useState(memories);

  useEffect(() => {
    setLocalMemories(memories);
  }, [memories]);

  useEffect(() => {
    if (editingMemory) {
      setEditedKey(editingMemory.key);
      setEditedValue(JSON.stringify(editingMemory.value, null, 2));
    }
  }, [editingMemory]);

  const handleEdit = (memory) => {
    setEditingMemory(memory);
  };

  const handleSave = async () => {
    let parsedValue;
    try {
      parsedValue = JSON.parse(editedValue);
    } catch (error) {
      parsedValue = editedValue;
    }
    try {
      await onUpdateMemory(editingMemory.key, { key: editedKey, value: parsedValue });
      setLocalMemories(prevMemories => 
        prevMemories.map(m => m.key === editingMemory.key ? { ...m, key: editedKey, value: parsedValue } : m)
      );
      setEditingMemory(null);
    } catch (error) {
      console.error("Error updating memory:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleDelete = async (key) => {
    try {
      await onDeleteMemory(key);
      setLocalMemories(prevMemories => prevMemories.filter(m => m.key !== key));
    } catch (error) {
      console.error("Error deleting memory:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleDeleteAll = async () => {
    try {
      await onDeleteAllMemories();
      setLocalMemories([]);
    } catch (error) {
      console.error("Error deleting all memories:", error);
      // Optionally, show an error message to the user
    }
  };

  const toggleExpand = (key) => {
    setExpandedMemories(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const renderMemoryValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    return value;
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className={`absolute inset-y-0 right-0 w-96 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 overflow-y-auto`}>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Manage Memories</h2>
          <button onClick={onClose} className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
            <X size={24} />
          </button>
        </div>
        
        {localMemories.map((memory) => (
          <div key={memory.key} className="mb-4 p-3 border rounded">
            {editingMemory && editingMemory.key === memory.key ? (
              <>
                <input
                  type="text"
                  value={editedKey}
                  onChange={(e) => setEditedKey(e.target.value)}
                  className={`w-full mb-2 p-1 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                />
                <textarea
                  value={editedValue}
                  onChange={(e) => setEditedValue(e.target.value)}
                  className={`w-full mb-2 p-1 rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                  rows="10"
                />
                <button onClick={handleSave} className="mr-2 p-1 rounded bg-green-500 text-white">
                  <Save size={18} />
                </button>
                <button onClick={() => setEditingMemory(null)} className="p-1 rounded bg-gray-500 text-white">
                  <X size={18} />
                </button>
              </>
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <h3 className="font-bold">{memory.key}</h3>
                  <button onClick={() => toggleExpand(memory.key)} className="p-1">
                    {expandedMemories[memory.key] ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                  </button>
                </div>
                {expandedMemories[memory.key] && (
                  <pre className="mt-2 whitespace-pre-wrap break-words">
                    {renderMemoryValue(memory.value)}
                  </pre>
                )}
                <div className="mt-2">
                  <button onClick={() => handleEdit(memory)} className="mr-2 p-1 rounded bg-blue-500 text-white">
                    <Edit size={18} />
                  </button>
                  <button onClick={() => handleDelete(memory.key)} className="p-1 rounded bg-red-500 text-white">
                    <Trash size={18} />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
        
        <button
          onClick={handleDeleteAll}
          className={`mt-4 w-full py-2 px-4 rounded ${
            darkMode ? 'bg-red-600 text-white hover:bg-red-700' : 'bg-red-500 text-white hover:bg-red-600'
          }`}
        >
          Delete All Memories
        </button>
      </div>
    </div>
  );
};

export default ManageMemories;