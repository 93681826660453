// src/weatherApi.js

const API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;

export async function fetchWeatherForCity(cityOrLat, lonOrNull = null) {
  let lat, lon;

  if (lonOrNull === null) {
    // If only one parameter is provided, assume it's a city name
    const cityData = await searchCities(cityOrLat);
    if (cityData.length === 0) {
      throw new Error('City not found');
    }
    lat = cityData[0].lat;
    lon = cityData[0].lon;
  } else {
    // If two parameters are provided, assume they're latitude and longitude
    lat = cityOrLat;
    lon = lonOrNull;
  }

  const currentWeatherUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}`;
  const forecastUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}`;

  try {
    const [currentWeatherResponse, forecastResponse] = await Promise.all([
      fetch(currentWeatherUrl),
      fetch(forecastUrl)
    ]);

    if (!currentWeatherResponse.ok || !forecastResponse.ok) {
      throw new Error('City not found or API error');
    }

    const [currentWeatherData, forecastData] = await Promise.all([
      currentWeatherResponse.json(),
      forecastResponse.json()
    ]);

    if (currentWeatherData && forecastData) {
      const currentTime = new Date(currentWeatherData.dt * 1000);
      return {
        city: currentWeatherData.name,
        country: currentWeatherData.sys.country,
        temperature: (currentWeatherData.main.temp - 273.15).toFixed(1), // Convert Kelvin to Celsius
        description: currentWeatherData.weather[0].description,
        date: currentTime.toLocaleDateString(),
        time: currentTime.toLocaleTimeString(),
        forecast24h: getForecast(forecastData.list, 24),
        forecastWeekly: getForecast(forecastData.list, 7 * 8),
        coordinates: {
          lat: currentWeatherData.coord.lat,
          lon: currentWeatherData.coord.lon
        }
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching weather:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
}

function getForecast(forecastList, hours) {
  const forecast = [];
  for (let i = 0; i < hours && i < forecastList.length; i++) {
    const forecastTime = new Date(forecastList[i].dt * 1000);
    forecast.push({
      date: forecastTime.toLocaleDateString(),
      time: forecastTime.toLocaleTimeString(),
      temperature: (forecastList[i].main.temp - 273.15).toFixed(1),
      description: forecastList[i].weather[0].description,
      icon: forecastList[i].weather[0].icon
    });
  }
  return forecast;
}

export async function searchCities(query) {
  const url = `https://api.openweathermap.org/geo/1.0/direct?q=${encodeURIComponent(query)}&limit=5&appid=${API_KEY}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch city data');
    }
    const data = await response.json();
    return data.map(city => ({
      name: city.name,
      country: city.country,
      state: city.state,
      lat: city.lat,
      lon: city.lon
    }));
  } catch (error) {
    console.error('Error searching cities:', error);
    return [];
  }
}

export async function fetchCitySuggestions(query) {
  return searchCities(query);
}